import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getCache, getAllCache, getItemFromArrayByKey } from '@/utils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Index.vue'),
  },
  {
    path: '/WeChatLogin',
    name: 'WeChatLogin',
    component: () => import('../views/login/weChatLogin.vue'),
  },
  {
    path: '/',
    name: 'main',
    component: () => import('../views/main/Index.vue'),
    redirect: '/home',
    children: [
      /**首页**/
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/Index.vue'),
        meta: {
          // //keepAlive: true
        }
      },

      /**基础功能**/
      {
        path: '/elevatorProperties',
        name: 'elevatorProperties',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorProperties/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorProperties1',
        name: 'elevatorProperties1',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorProperties/Index1.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorProperties2',
        name: 'elevatorProperties2',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorProperties/Index2.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorProperties3',
        name: 'elevatorProperties3',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorProperties/Index3.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorProperties4',
        name: 'elevatorProperties4',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorProperties/Index4.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorProperties5',
        name: 'elevatorProperties5',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorProperties/Index5.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorProperties6',
        name: 'elevatorProperties6',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorProperties/Index6.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorProperties7',
        name: 'elevatorProperties7',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorProperties/Index7.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorProperties8',
        name: 'elevatorProperties8',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorProperties/Index8.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorDraft',
        name: 'elevatorDraft',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorDraft/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorArchives',
        name: 'elevatorArchives',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorArchives/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorArchivesDetail',
        name: 'elevatorArchivesDetail',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorArchives/DetailPage.vue'),
      },
      {
        path: '/timeLiftMonitoring',
        name: 'timeLiftMonitoring',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorArchives/timeMonitoring/Index.vue'),
      },
      {
        path: '/elevatorCheck',
        name: 'elevatorCheck',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorCheck/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorMap',
        name: 'elevatorMap',
        component: () => import('../views/facilitiesEquipment/elevator/elevatorMap/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      /**物联网故障管理**/
      {
        path: '/IoTFailure',
        name: 'IoTFailure',
        component: () => import('../views/facilitiesEquipment/elevator/wlwbj/wlwMalfunction/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/IoTAlarm',
        name: 'IoTAlarm',
        component: () => import('../views/facilitiesEquipment/elevator/wlwbj/wlwAlarm/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/IoTEvent',
        name: 'IoTEvent',
        component: () => import('../views/facilitiesEquipment/elevator/wlwbj/wlwEvent/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorEvent',
        name: 'elevatorEvent',
        component: () => import('../views/facilitiesEquipment/elevator/wlwbj/elevatorEvent/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/liftCodeItems',
        name: 'liftCodeItems',
        component: () => import('../views/facilitiesEquipment/elevator/wlwbj/codeItems/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },

      /**物业监管试图**/
      {
        path: '/superviseBigData',
        name: 'superviseBigData',
        component: () => import('../views/bigData/superviseBigData/main/Index.vue')
      },
      {
        path: '/projectBigData',
        name: 'projectBigData',
        component: () => import('../views/bigData/projectBigData/Index.vue')
      },


      /**基础功能管理**/
      // 项目档案
      {
        path: '/monitorfile',
        name: 'monitorfile',
        component: () => import('../views/jcxx/monitorfile/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/timeMonitoring',
        name: 'timeMonitoring',
        component: () => import('../views/jcxx/monitorfile/timeMonitoring/Index.vue'),
      },
      // 组织机构管理
      {
        path: '/userDept',
        name: 'userDept',
        component: () => import('../views/jcxx/userDept/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //行政区域
      {
        path: '/adminDivision',
        name: 'adminDivision',
        component: () => import('../views/jcxx/adminDivision/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorProperties7',
        name: 'elevatorProperties7',
        component: () => import('../views/jcxx/elevatorProperties/Index1.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/elevatorProperties8',
        name: 'elevatorProperties8',
        component: () => import('../views/jcxx/elevatorProperties/Index2.vue'),
        meta: {
          //keepAlive: true
        }
      },


      /**网络管理**/
      // 监测设备管理
      {
        path: '/monitorList',
        name: 'monitorList',
        component: () => import('V/monitorDevice/monitoring/deviceList/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      // 监测设备组管理
      {
        path: '/monitorGroupList',
        name: 'monitorGroupList',
        component: () => import('V/monitorDevice/monitoring/monitorGroup/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      // 远程升级
      {
        path: '/remoteupdate',
        name: 'remoteupdate',
        component: () => import('V/monitorDevice/monitoring/remoteUpdate/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/enquipmentList',
        name: 'enquipmentList',
        component: () => import('../views/monitorDevice/monitoringEquipment/enquipmentList/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/devicetypeList',
        name: 'devicetypeList',
        component: () => import('../views/monitorDevice/monitoringEquipment/devicetypeList/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/equipmentGroup',
        name: 'equipmentGroup',
        component: () => import('../views/monitorDevice/monitoringEquipment/equipmentGroup/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/recoverLog',
        name: 'recoverLog',
        component: () => import('../views/monitorDevice/monitoringEquipment/recoverLog/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/paramsList',
        name: 'paramsList',
        component: () => import('../views/monitorDevice/monitoringEquipment/paramsTreeManager/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/paramsConfig',
        name: 'paramsConfig',
        component: () => import('../views/monitorDevice/monitoringEquipment/paramsTreeManager/paramsConfig.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/paramsConfigs',
        name: 'paramsConfigs',
        component: () => import('../views/monitorDevice/monitoringEquipment/equipmentGroup/paramsConfig.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/softManagement',
        name: 'softManagement',
        component: () => import('../views/monitorDevice/monitoringEquipment/softManagement/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/paramsConfigHistory',
        name: 'paramsConfigHistory',
        component: () => import('../views/monitorDevice/monitoringEquipment/paramsConfigHistory/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/paramsBatchHistory',
        name: 'paramsBatchHistory',
        component: () => import('../views/monitorDevice/monitoringEquipment/paramsBatchHistory/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/remoteList',
        name: 'remoteList',
        component: () => import('V/monitorDevice/monitoring/remoteList/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/paramsMonitor',
        name: 'paramsMonitor',
        component: () => import('../views/monitorDevice/monitoring/deviceList/params/paramsConfig.vue'),
        meta: {
          // //keepAlive: true
        }
      },
      //实时预览
      {
        path: '/preview',
        name: 'preview',
        component: () => import('../views/monitorDevice/monitoring/preview/preview.vue'),
        meta: {
          // //keepAlive: true
        }
      },
      //视频回放
      {
        path: '/playback',
        name: 'playback',
        component: () => import('../views/monitorDevice/monitoring/playback/playback.vue'),
        meta: {
          // //keepAlive: true
        }
      },
      {
        path: '/netReport',
        name: 'netReport',
        component: () => import('../views/monitorDevice/netReport/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/upgradeLog',
        name: 'upgradeLog',
        component: () => import('../views/monitorDevice/monitoringEquipment/upgradeLog/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/recoverlog',
        name: 'recoverlog',
        component: () => import('../views/monitorDevice/monitoringEquipment/recoverLog/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //平台自检日志
      {
        path: '/selfTestLog',
        name: 'selfTestLog',
        component: () => import('V/monitorDevice/selfTest/platSelfTest/selfTestLog.vue')
      },
      //平台自检异常日志
      {
        path: '/abnormalLog',
        name: 'abnormalLog',
        component: () => import('V/monitorDevice/selfTest/platSelfTest/abnormalLog.vue')
      },
      //终端自检日志
      {
        path: '/devSelfTestLog',
        name: 'devSelfTestLog',
        component: () => import('V/monitorDevice/selfTest/devSelfTest/devSelfTestLog.vue')
      },
      //终端自检异常日志
      {
        path: '/devAbnormalLog',
        name: 'devAbnormalLog',
        component: () => import('V/monitorDevice/selfTest/devSelfTest/devAbnormalLog.vue')
      },
      //异常码流
      {
        path: '/abnormalStream',
        name: 'abnormalStream',
        component: () => import('V/monitorDevice/abnormalStream/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //TF卡异常
      {
        path: '/tfAbnormal',
        name: 'tfAbnormal',
        component: () => import('V/monitorDevice/selfTest/devSelfTest/tfAbnormal.vue'),
        meta: {
          //keepAlive: true
        }
      },


      /**异常历史**/
      {
        path: '/wlwEvent',
        name: 'wlwEvent',
        component: () => import('../views/abnormalHistory/wlwbj/wlwEvent/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/alarmHistory',
        name: 'alarmHistory',
        component: () => import('../views/abnormalHistory/wlwbj/alarmHistory/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/unhandleAlarm',
        name: 'unhandleAlarm',
        component: () => import('../views/abnormalHistory/wlwbj/unhandleEvent/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/eventHistory',
        name: 'eventHistory',
        component: () => import('../views/abnormalHistory/event/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/communication',
        name: 'communication',
        component: () => import('../views/abnormalHistory/communicate/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/codeItems',
        name: 'codeItems',
        component: () => import('V/abnormalHistory/wlwbj/codeItems/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/deviceItems',
        name: 'deviceItems',
        component: () => import('V/abnormalHistory/wlwbj/deviceItems/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/abnormalHistory',
        name: 'abnormalHistory',
        component: () => import('../views/abnormalHistory/abnormal/abnormalHistory/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/unhandleAbnormal',
        name: 'unhandleAbnormal',
        component: () => import('../views/abnormalHistory/abnormal/unhandleAbnormal/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },


      /**业务管理**/
      //绿化台账
      {
        path: '/ledgerAfforest',
        name: 'ledgerAfforest',
        component: () => import('../views/businessManagement/standingBook/ledgerAfforest/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //清洁台账
      {
        path: '/ledgerClean',
        name: 'ledgerClean',
        component: () => import('../views/businessManagement/standingBook/ledgerClean/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //消杀台账
      {
        path: '/ledgerDisinfection',
        name: 'ledgerDisinfection',
        component: () => import('../views/businessManagement/standingBook/ledgerDisinfection/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //白蚁防治台账
      {
        path: '/ledgerTermiteControl',
        name: 'ledgerTermiteControl',
        component: () => import('../views/businessManagement/standingBook/ledgerTermiteControl/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //项目标识台账
      {
        path: '/ledgerItemIdentification',
        name: 'ledgerItemIdentification',
        component: () => import('../views/businessManagement/standingBook/ledgerItemIdentification/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //管理标识台账
      {
        path: '/ledgerManageIdentification',
        name: 'ledgerManageIdentification',
        component: () => import('../views/businessManagement/standingBook/ledgerManageIdentification/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //管理费台账
      {
        path: '/ledgerManagementCost',
        name: 'ledgerManagementCost',
        component: () => import('../views/businessManagement/standingBook/ledgerManagementCost/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //停车场台账
      {
        path: '/ledgerParking',
        name: 'ledgerParking',
        component: () => import('../views/businessManagement/standingBook/ledgerParking/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //项目建设规模台账
      {
        path: '/ledgerProjectConstruction',
        name: 'ledgerProjectConstruction',
        component: () => import('../views/businessManagement/standingBook/ledgerProjectConstruction/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //风险台账
      {
        path: '/ledgerRisk',
        name: 'ledgerRisk',
        component: () => import('../views/businessManagement/standingBook/ledgerRisk/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //作业单位管理
      {
        path: '/workUnits',
        name: 'workUnits',
        component: () => import('../views/businessManagement/environment/workUnits/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //作业计划
      {
        path: '/workPlan',
        name: 'workPlan',
        component: () => import('../views/businessManagement/environment/workPlan/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //作业任务
      {
        path: '/workOrder',
        name: 'workOrder',
        component: () => import('../views/businessManagement/environment/workOrder/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //垃圾清运记录
      {
        path: '/garbageClearance',
        name: 'garbageClearance',
        component: () => import('../views/businessManagement/environment/garbageClearance/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //垃圾清运统计
      {
        path: '/garbageStatistics',
        name: 'garbageStatistics',
        component: () => import('../views/businessManagement/environment/garbageClearance/statistics.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //特别事件报告
      {
        path: '/specialEvent',
        name: 'specialEvent',
        component: () => import('../views/businessManagement/reportForms/specialEvent/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //年度运营计划
      {
        path: '/yearOperationalPlan',
        name: 'yearOperationalPlan',
        component: () => import('../views/businessManagement/reportForms/operatingStatements/year/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //月度实际数据录入
      {
        path: '/monthOperationalPlan',
        name: 'monthOperationalPlan',
        component: () => import('../views/businessManagement/reportForms/operatingStatements/month/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //图表查看
      {
        path: '/operationSituation',
        name: 'operationSituation',
        component: () => import('../views/businessManagement/reportForms/operatingStatements/chart/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },

      /**数据分析与报告**/
      {
        path: '/abnormalAnalysis',
        name: 'abnormalAnalysis',
        component: () => import('../views/dataAnalysis/abnormalAnalysis.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/alarmAnalysis',
        name: 'alarmAnalysis',
        component: () => import('../views/dataAnalysis/alarmAnalysis.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/processTime',
        name: 'processTime',
        component: () => import('../views/dataAnalysis/processTime.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/alarmProcessTime',
        name: 'alarmProcessTime',
        component: () => import('../views/dataAnalysis/alarmProcessTime.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/eventCheckHistory',
        name: 'eventCheckHistory',
        component: () => import('../views/workCheck/eventCheckHistory/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/analysisReport',
        name: 'analysisReport',
        component: () => import('../views/dataAnalysis/analysisReport'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/majorissuesReport',
        name: 'majorissuesReport',
        component: () => import('../views/dataAnalysis/majorissuesReport'),
        meta: {
          //keepAlive: true
        }
      },
      //抄表信息报表
      {
        path: '/meterReadingReport',
        name: 'meterReadingReport',
        component: () => import('../views/dataAnalysis/meterReadingReport'),
        meta: {
          //keepAlive: true
        }
      },
      //设施设备保养情况报表
      {
        path: '/equipMaintenanceReport',
        name: 'equipMaintenanceReport',
        component: () => import('../views/dataAnalysis/equipMaintenanceReport'),
        meta: {
          keepAlive: true
        }
      },
      //报事报修分析报表
      {
        path: '/repairAndMatterReport',
        name: 'repairAndMatterReport',
        component: () => import('../views/dataAnalysis/repairAndMatterReport'),
        meta: {
          keepAlive: true
        }
      },
      //设施设备保养情况报表
      {
        path: '/environmentAnalysis',
        name: 'environmentAnalysis',
        component: () => import('../views/dataAnalysis/environmentAnalysis'),
        meta: {
          keepAlive: true
        }
      },
      //一键生成报告
      {
        path: '/getReport',
        name: 'getReport',
        component: () => import('V/dataAnalysis/report/Index'),
        meta: {
        }
      },
      //报告历史
      {
        path: '/reportHistory',
        name: 'reportHistory',
        component: () => import('V/dataAnalysis/report/reportHistory.vue'),
        meta: {
        }
      },

      /**工作查阅与报告**/
      //督导工作记录
      {
        path: '/superviseRecording',
        name: 'superviseRecording',
        component: () => import('V/supervise/superviseRecording/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //品质改进单
      {
        path: '/improveOrder',
        name: 'improveOrder',
        component: () => import('V/supervise/improveOrder/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //督导记录查询
      {
        path: '/superviseHistory',
        name: 'superviseHistory',
        component: () => import('V/supervise/superviseHistory/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //品质改进分析报表
      {
        path: '/improveOrderReport',
        name: 'improveOrderReport',
        component: () => import('V/workCheck/improveOrderReport/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //交接班历史
      {
        path: '/shiftHistory',
        name: 'shiftHistory',
        component: () => import('../views/workCheck/patrol/shiftHistory/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //工程交接班历史
      {
        path: '/engineerShiftHistory',
        name: 'engineerShiftHistory',
        component: () => import('../views/workCheck/engineer/shiftHistory/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //巡查记录
      {
        path: '/newPatrolRecord',
        name: 'newPatrolRecord',
        component: () => import('../views/patrols/patrolsRecord/patrolsRecordData/newPatrolRecord.vue'),
        meta: {
        }
      },
      //人工巡查
      {
        path: '/artificialPatrolRecord',
        name: 'artificialPatrolRecord',
        component: () => import('V/workCheck/patrol/patrolCheck/artificialPatrolLine.vue'),
        meta: {
          keepAlive: true
        }
      },
      //工程人工巡查
      {
        path: '/artificialEngineerPatrolRecord',
        name: 'artificialEngineerPatrolRecord',
        component: () => import('V/workCheck/engineer/patrolCheck/artificialPatrolLine.vue'),
        meta: {
          keepAlive: true
        }
      },
      //工程巡查记录
      {
        path: '/engineer_inspection',
        name: 'engineer_inspection',
        component: () => import('../views/engineer/patrolsRecord/patrolsRecordData/newPatrolRecord.vue'),
        meta: {
        }
      },
      //巡查记录查询
      {
        path: '/patrolRecord',
        name: 'patrolRecord',
        component: () => import('V/workCheck/patrol/patrolCheck/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //工程巡查记录查询(系统巡查)
      {
        path: '/engineerPatrolRecord',
        name: 'engineerPatrolRecord',
        component: () => import('V/workCheck/engineer/patrolCheck/Index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //工程巡查记录查询(现场巡查)
      {
        path: '/artificialEngineerPatrolRecord',
        name: 'artificialEngineerPatrolRecord',
        component: () => import('V/workCheck/engineer/patrolCheck/artificialPatrol.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/abnormalRecord',
        name: 'abnormalRecord',
        component: () => import('../views/patrols/patrolsRecord/abnormalRecord/Index.vue'),
        meta: {
        }
      },
      {
        path: '/alarmRecord',
        name: 'alarmRecord',
        component: () => import('../views/patrols/patrolsRecord/alarmRecord/Index.vue'),
        meta: {
        }
      },
      {
        path: '/followUp',
        name: 'followUp',
        component: () => import('../views/patrols/patrolsRecord/followUp/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //消防值班报表
      {
        path: '/fireRecord',
        name: 'fireRecord',
        component: () => import('../views/patrols/patrolsRecord/fireRecord/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //员工工作状态查询
      {
        path: '/employeeOrderStatus',
        name: 'employeeOrderStatus',
        component: () => import('V/workCheck/employeeStatus/order/Index.vue'),
        meta: {
        }
      },
      //员工工作次数查询
      {
        path: '/employeeNumStatus',
        name: 'employeeNumStatus',
        component: () => import('V/workCheck/employeeStatus/total/Index.vue'),
        meta: {
        }
      },
      //报事报修
      {
        path: '/matterRecord',
        name: 'matterRecord',
        component: () => import('V/matterAndRepairs/matter/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/repairsRecord',
        name: 'repairsRecord',
        component: () => import('V/matterAndRepairs/repair/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/personPool',
        name: 'personPool',
        component: () => import('V/matterAndRepairs/repair/personPool/repairsGroupManagement.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/planRepairs',
        name: 'planRepairs',
        component: () => import('V/matterAndRepairs/planRepairs/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/thirdOrder',
        name: 'thirdOrder',
        component: () => import('V/matterAndRepairs/thirdOrder/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/residentInfo',
        name: 'residentInfo',
        component: () => import('V/matterAndRepairs/residentInfo/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/myTodo',
        name: 'myTodo',
        component: () => import('V/matterAndRepairs/myTodo/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //远程控制
      {
        path: '/remoteControl',
        name: 'remoteControl',
        component: () => import('../views/patrols/remoteControl/remoteControl'),
        meta: {
          //keepAlive: true
        }
      },


      /**项目设置**/
      //巡查方案设置
      {
        path: '/patrolSetting',
        name: 'patrolSetting',
        component: () => import('../views/patrols/patrolsSetting/patrolScheme/Index.vue'),
        meta: {
        }
      },
      {
        path: '/patrolTemplate',
        name: 'patrolTemplate',
        component: () => import('../views/material/patrolScheme/patrolTemplate'),
        meta: {
        }
      },
      {
        path: '/addOrEditPatrolsRecord',
        name: 'addOrEditPatrolsRecord',
        component: () => import('../views/patrols/patrolsRecord/patrolsRecordData/addOrEditPatrolsRecord.vue'),
        meta: {
        }
      },
      {
        path: '/patrolScheme',
        name: 'patrolScheme',
        component: () => import('../views/patrols/patrolsSetting/patrolScheme/patrolScheme.vue'),
        meta: {
        }
      },

      //工程巡查方案设置
      {
        path: '/engineerPatrolSetting',
        name: 'engineerPatrolSetting',
        component: () => import('../views/engineer/patrolsSetting/patrolScheme/Index.vue'),
        meta: {
        }
      },
      {
        path: '/addOrEditEngineerPatrolsRecord',
        name: 'addOrEditEngineerPatrolsRecord',
        component: () => import('../views/engineer/patrolsRecord/patrolsRecordData/addOrEditEngineerPatrolsRecord.vue'),
        meta: {
        }
      },
      {
        path: '/engineerPatrolScheme',
        name: 'engineerPatrolScheme',
        component: () => import('../views/engineer/patrolsSetting/patrolScheme/patrolScheme.vue'),
        meta: {
        }
      },
      {
        path: '/engineerPatrolTemplate',
        name: 'engineerPatrolTemplate',
        component: () => import('../views/material/engineerPatrolScheme/patrolTemplate'),
        meta: {
        }
      },

      {
        path: '/equipmentList',
        name: 'equipmentList',
        component: () => import('../views/patrols/patrolsSetting/equipmentList/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/basemap',
        name: 'basemap',
        component: () => import('../views/patrols/patrolsSetting/basemap/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/storeFiles',
        name: 'storeFiles',
        component: () => import('../views/patrols/patrolsSetting/storeFiles/Index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //巡查方案
      {
        path: '/patrolSchemeFile',
        name: 'patrolSchemeFile',
        component: () => import('../views/material/patrolScheme/Index'),
        meta: {
          // keepAlive: true
        }
      },
      //工程巡查方案
      {
        path: '/engineerPatrolSchemeFile',
        name: 'engineerPatrolSchemeFile',
        component: () => import('../views/material/engineerPatrolScheme/Index'),
        meta: {
          // keepAlive: true
        }
      },
      {
        path: '/applicationFile',
        name: 'applicationFile',
        component: () => import('../views/material/applicationFile/Index'),
        meta: {
          //keepAlive: true
        }
      },


      /**场景管理**/
      //场景模板设置
      {
        path: '/dragToGenerate',
        name: 'dragToGenerate',
        component: () => import('V/SceneManagement/DragToGenerate'),
        meta: {
        }
      },
      //场景模板管理
      {
        path: '/waterSystem',
        name: 'waterSystem',
        component: () => import('V/SceneManagement/waterSystem/Index'),
        meta: {
        }
      },
      //场景运行数据
      {
        path: '/waterSystemStatusData',
        name: 'waterSystemStatusData',
        component: () => import('V/SceneManagement/waterSystem/WaterSystemStatusData'),
        meta: {
        }
      },
      //电系统场景模板管理
      {
        path: '/electricitySystem',
        name: 'electricitySystem',
        component: () => import('V/SceneManagement/electricitySystem/Index'),
        meta: {
        }
      },
      //电系统场景运行数据
      {
        path: '/electricitySystemStatusData',
        name: 'electricitySystemStatusData',
        component: () => import('V/SceneManagement/electricitySystem/ElectricitySystemStatusData'),
        meta: {
        }
      },


      /**设施设备管理**/
      //设施设备名录
      {
        path: '/directories',
        name: 'directories',
        component: () => import('V/facilityAndEquipment/basicManagement/directories/Index'),
        meta: {
          keepAlive: true
        }
      },
      //总线设备
      {
        path: '/busDevice',
        name: 'busDevice',
        component: () => import('V/facilityAndEquipment/basicManagement/busDevice/Index'),
        meta: {
          keepAlive: true
        }
      },
      //设备机房
      {
        path: '/machineRoom',
        name: 'machineRoom',
        component: () => import('V/facilityAndEquipment/basicManagement/machineRoom/Index'),
        meta: {
          keepAlive: true
        }
      },
      //运行管理
      {
        path: '/runManagement',
        name: 'runManagement',
        component: () => import('V/facilityAndEquipment/runManagement/Index'),
        meta: {
        }
      },
      {
        path: '/operationPlan',
        name: 'operationPlan',
        component: () => import('V/workCheck/operationPlan/Index'),
        meta: {
        }
      },
      //维保单位管理
      {
        path: '/maintenanceunit',
        name: 'maintenanceunit',
        component: () => import('V/facilityAndEquipment/maintenanceManagement/unit/maintenanceunit'),
        meta: {
        }
      },
      //自保单位管理
      {
        path: '/selfmaintenanceunit',
        name: 'selfmaintenanceunit',
        component: () => import('V/facilityAndEquipment/maintenanceManagement/unit/selfmaintenanceunit'),
        meta: {
        }
      },
      //保养计划
      {
        path: '/maintenancePlan',
        name: 'maintenancePlan',
        component: () => import('V/facilityAndEquipment/maintenanceManagement/maintenance/plan/Index'),
        meta: {
          keepAlive: true
        }
      },
      //保养任务执行看板
      {
        path: '/planExecuteBoard',
        name: 'planExecuteBoard',
        component: () => import('V/facilityAndEquipment/maintenanceManagement/maintenance/plan/planExecuteBoard'),
        meta: {
          keepAlive: true
        }
      },
      //保养任务
      {
        path: '/maintenanceTask',
        name: 'maintenanceTask',
        component: () => import('V/facilityAndEquipment/maintenanceManagement/maintenance/task/Index'),
        meta: {
          keepAlive: true
        }
      },
      //维修计划
      {
        path: '/repairPlan',
        name: 'repairPlan',
        component: () => import('V/facilityAndEquipment/maintenanceManagement/repair/plan/Index'),
        meta: {
          keepAlive: true
        }
      },
      //保养任务执行看板
      {
        path: '/planRepairExecuteBoard',
        name: 'planRepairExecuteBoard',
        component: () => import('V/facilityAndEquipment/maintenanceManagement/repair/plan/planExecuteBoard'),
        meta: {
          keepAlive: true
        }
      },
      //维修任务
      {
        path: '/repairTask',
        name: 'repairTask',
        component: () => import('V/facilityAndEquipment/maintenanceManagement/repair/task/Index'),
        meta: {
          keepAlive: true
        }
      },

      /**物业巡查**/
      {
        path: '/inspectionRecord',
        name: 'inspectionRecord',
        component: () => import('V/propertyInspection/inspectionRecord/Index'),
        meta: {
        }
      },
      //巡查点档案
      {
        path: '/inspectionPoint',
        name: 'inspectionPoint',
        component: () => import('V/patrols/patrolsSetting/inspectionPoint/Index'),
        meta: {
        }
      },


      /**Ulake Lab**/
      {
        path: '/fireRecordList',
        name: 'fireRecordList',
        component: () => import('V/ulakeLab/fireRecord/Index'),
        meta: {
        }
      },
      {
        path: '/fireItemList',
        name: 'fireItemList',
        component: () => import('V/ulakeLab/fireItemList/Index'),
        meta: {
        }
      },
      //未确认报警记录
      {
        path: '/unConfirmAlarm',
        name: 'unConfirmAlarm',
        component: () => import('V/ulakeLab/unConfirmAlarm/Index'),
        meta: {
        }
      },
      //项目归属变动
      {
        path: '/monitorPointChange',
        name: 'monitorPointChange',
        component: () => import('V/ulakeLab/monitorPointChange/Index'),
        meta: {
        }
      },
      //巡查记录异常
      {
        path: '/patrolRecordAbnormal',
        name: 'patrolRecordAbnormal',
        component: () => import('V/ulakeLab/patrolRecord/Index'),
        meta: {
        }
      },
      //欠费
      {
        path: '/arrears',
        name: 'arrears',
        component: () => import('../views/dataAnalysis/performance/arrears/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //违约
      {
        path: '/violation',
        name: 'violation',
        component: () => import('../views/dataAnalysis/performance/violation/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //服务及收费
      {
        path: '/servicesFees',
        name: 'servicesFees',
        component: () => import('../views/dataAnalysis/performance/servicesFees/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //外委单位
      {
        path: '/outsourcingUnitInfo',
        name: 'outsourcingUnitInfo',
        component: () => import('../views/dataAnalysis/performance/outsourcingUnitInfo/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //服务企业信息
      {
        path: '/servedEnterpriseInfo',
        name: 'servedEnterpriseInfo',
        component: () => import('../views/dataAnalysis/performance/serveEnterpriseInfo/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //四大文件查看
      {
        path: '/fileLookup',
        name: 'fileLookup',
        component: () => import('../views/dataAnalysis/performance/fileLookup/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //物业费收支
      {
        path: '/propertyFees',
        name: 'propertyFees',
        component: () => import('../views/dataAnalysis/performance/propertyFees/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //公共收入收支
      {
        path: '/publicRevenue',
        name: 'publicRevenue',
        component: () => import('../views/dataAnalysis/performance/publicRevenue/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //专项维修资金收支
      {
        path: '/specialMaintenanceFunds',
        name: 'specialMaintenanceFunds',
        component: () => import('../views/dataAnalysis/performance/specialMaintenanceFunds/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //专项服务工作完成情况
      {
        path: '/propertyWorkSpecial',
        name: 'propertyWorkSpecial',
        component: () => import('../views/dataAnalysis/performance/propertyWork/propertyWorkSpecial/index.vue'),
        meta: {
          keepAlive: true
        }
      },

      //秩序维护及报事处理情况
      {
        path: '/propertyWorkHandle',
        name: 'propertyWorkHandle',
        component: () => import('../views/dataAnalysis/performance/propertyWork/propertyWorkHandle/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //绿化养护情况
      {
        path: '/propertyWorkAfforest',
        name: 'propertyWorkAfforest',
        component: () => import('../views/dataAnalysis/performance/propertyWork/propertyWorkAfforest/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //消杀与白蚁防治
      {
        path: '/propertyWorkSterilize',
        name: 'propertyWorkSterilize',
        component: () => import('../views/dataAnalysis/performance/propertyWork/propertyWorkSterilize/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //清洁服务情况
      {
        path: '/propertyWorkCleaning',
        name: 'propertyWorkCleaning',
        component: () => import('../views/dataAnalysis/performance/propertyWork/propertyWorkCleaning/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //垃圾处理
      {
        path: '/propertyWorkWaste',
        name: 'propertyWorkWaste',
        component: () => import('../views/dataAnalysis/performance/propertyWork/propertyWorkWaste/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //设施设备运行维修保养情况
      {
        path: '/propertyWorkDevice',
        name: 'propertyWorkDevice',
        component: () => import('../views/dataAnalysis/performance/propertyWork/propertyWorkDevice/index.vue'),
        meta: {
          keepAlive: true
        }
      },
        //承接查验问题整改情况
      {
        path: '/propertyWorkUndertakeInspection',
        name: 'propertyWorkUndertakeInspection',
        component: () => import('../views/dataAnalysis/performance/propertyWork/propertyWorkUndertakeInspection/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //项目巡查情况
      {
        path: '/propertyWorkProjectInspection',
        name: 'propertyWorkProjectInspection',
        component: () => import('../views/dataAnalysis/performance/propertyWork/propertyWorkProjectInspection/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //调度中心工作情况
      {
        path: '/propertyWorkDispatchCenter',
        name: 'propertyWorkDispatchCenter',
        component: () => import('../views/dataAnalysis/performance/propertyWork/propertyWorkDispatchCenter/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //项目情况简介-绿化
      {
        path: '/projectInfoAfforest',
        name: 'projectInfoAfforest',
        component: () => import('../views/dataAnalysis/performance/projectInfo/projectInfoAfforest/index.vue'),
        meta: {
          keepAlive: true
        }
      },

      //项目情况简介-停车场
      {
        path: '/projectInfoPark',
        name: 'projectInfoPark',
        component: () => import('../views/dataAnalysis/performance/projectInfo/projectInfoPark/index.vue'),
        meta: {
          keepAlive: true
        }
      },

      //项目情况简介-白蚁防治
      {
        path: '/projectInfoTermiteControl',
        name: 'projectInfoTermiteControl',
        component: () => import('../views/dataAnalysis/performance/projectInfo/projectInfoTermiteControl/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //项目情况简介-共用设施设备
      {
        path: '/projectInfoShare',
        name: 'projectInfoShare',
        component: () => import('../views/dataAnalysis/performance/projectInfo/projectInfoShare/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //项目情况简介-消杀
      {
        path: '/projectInfoSterilize',
        name: 'projectInfoSterilize',
        component: () => import('../views/dataAnalysis/performance/projectInfo/projectInfoSterilize/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //项目情况简介-清洁
      {
        path: '/projectInfoCleaning',
        name: 'projectInfoCleaning',
        component: () => import('../views/dataAnalysis/performance/projectInfo/projectInfoCleaning/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //项目情况简介-项目建设规模
      {
        path: '/projectInfoScale',
        name: 'projectInfoScale',
        component: () => import('../views/dataAnalysis/performance/projectInfo/projectInfoScale/index.vue'),
        meta: {
          keepAlive: true
        }
      },

      //履约公示-承接查验
      {
        path: '/undertakeInspection',
        name: 'undertakeInspection',
        component: () => import('../views/dataAnalysis/performance/undertakeInspection/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //手动生成履约报告
      {
        path: '/performanceReport',
        name: 'performanceReport',
        component: () => import('../views/dataAnalysis/performance/report/index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //自动生成履约报告
      {
        path: '/enforcementReport',
        name: 'enforcementReport',
        component: () => import('../views/dataAnalysis/performance/report/autoReport/index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //通知
      {
        path: '/notification',
        name: 'notification',
        component: () => import('../views/dataAnalysis/performance/propertyNotification/notification/index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //公告
      {
        path: '/announcement',
        name: 'announcement',
        component: () => import('../views/dataAnalysis/performance/propertyNotification/announcement/index.vue'),
        meta: {
          //keepAlive: true
        }
      },
      //测试页面
      {
        path: '/testpage',
        name: 'testpage',
        component: () => import('../views/testpage/test'),
        meta: {
          // //keepAlive: true
        }
      },


      /**系统配置**/
      {
        path: '/userCenter',
        name: 'userCenter',
        component: () => import('../views/xtpz/userCenter.vue'),
        meta: {
          // //keepAlive: true
        }
      },
      {
        path: '/peopleManagement',
        name: 'peopleManagement',
        component: () => import('../views/xtpz/yhgl/peopleManagement.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/maintenancePeopleManagement',
        name: 'maintenancePeopleManagement',
        component: () => import('../views/xtpz/yhgl/maintenancePeopleManagement.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/peopleDistributed',
        name: 'peopleDistributed',
        component: () => import('../views/xtpz/yhgl/peopleDistributed.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/maintenanceGroupManagement',
        name: 'maintenanceGroupManagement',
        component: () => import('V/facilityAndEquipment/maintenanceManagement/maintenanceGroup/maintenanceGroupManagement.vue'),
        meta: {
          // //keepAlive: true
        }
      },
      {
        path: '/selfMaintainGroupManagement',
        name: 'selfMaintainGroupManagement',
        component: () => import('V/facilityAndEquipment/maintenanceManagement/maintenanceGroup/selfMaintainGroupManagement.vue'),
        meta: {
          // //keepAlive: true
        }
      },
      {
        path: '/roleManagement',
        name: 'roleManagement',
        component: () => import('../views/xtpz/roleManagement.vue'),
        meta: {
          // //keepAlive: true
        }
      },
      {
        path: '/menuManagement',
        name: 'menuManagement',
        component: () => import('../views/xtpz/menuManagement.vue'),
        meta: {
          // //keepAlive: true
        }
      },
      {
        path: '/logManagement',
        name: 'logManagement',
        component: () => import('../views/xtpz/logManagement.vue'),
        meta: {
          //keepAlive: true
        }
      },
      {
        path: '/remoteEnable',
        name: 'remoteEnable',
        component: () => import('V/tysz/remoteEnable'),
        meta: {
          keepAlive: true
        }
      },
      //远程管理
      {
        path: '/remoteManagement',
        name: 'remoteManagement',
        component: () => import('../views/tysz/remoteManagement'),
        meta: {
          // //keepAlive: true
        }
      },
      //FRP端口管理
      {
        path: '/FRPManagement',
        name: 'FRPManagement',
        component: () => import('../views/tysz/FRPManagement'),
        meta: {
          // //keepAlive: true
        }
      },
      //终端高级参数
      {
        path: '/advanceparam',
        name: 'advanceparam',
        component: () => import('../views/tysz/advanceparam'),
        meta: {
          //keepAlive: true
        }
      },
      //项目参数
      {
        path: '/monitorpointparam',
        name: 'monitorpointparam',
        component: () => import('../views/tysz/monitorpointparam'),
        meta: {
          //keepAlive: true
        }
      },
      //平台参数
      {
        path: '/paramset',
        name: 'paramset',
        component: () => import('../views/tysz/paramset'),
        meta: {
          // //keepAlive: true
        }
      },
      {
        path: '/functionparams',
        name: 'functionparams',
        component: () => import('../views/tysz/functionParams'),
        meta: {
          // //keepAlive: true
        }
      },
      //用户操作说明
      {
        path: '/xmddy',
        name: 'xmddy',
        component: () => import('../views/xtpz/usersmanual/xmddy'),
        meta: {
        }
      },
      {
        path: '/xmgly',
        name: 'xmgly',
        component: () => import('../views/xtpz/usersmanual/xmgly'),
        meta: {
        }
      },
      {
        path: '/gsddy',
        name: 'gsddy',
        component: () => import('../views/xtpz/usersmanual/gsddy'),
        meta: {
        }
      },
      {
        path: '/xtwhgly',
        name: 'xtwhgly',
        component: () => import('../views/xtpz/usersmanual/xtwhgly'),
        meta: {
        }
      },
      {
        path: '/phoneapp',
        name: 'phoneapp',
        component: () => import('../views/xtpz/usersmanual/phoneapp'),
        meta: {
        }
      },
      //字典管理
      {
        path: '/dictionaryManagement',
        name: 'dictionaryManagement',
        component: () => import('../views/xtpz/dictionary/dictionaryManagement.vue'),
        meta: {
          keepAlive: true
        }
      },
      //字典类型管理
      {
        path: '/dictionaryTypeManagement',
        name: 'dictionaryTypeManagement',
        component: () => import('../views/xtpz/dictionary/dictionaryTypeManagement.vue'),
        meta: {
          keepAlive: true
        }
      },
      //关于本系统
      {
        path: '/aboutSystem',
        name: 'aboutSystem',
        component: () => import('V/xtpz/aboutSystem.vue')
      },
      //能耗管理-能耗表台账
      {
        path: '/ecRecord',
        name: 'ecRecord',
        component: () => import('../views/facilityAndEquipment/runManagement/energyConsumptionManagement/ecRecord/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //能耗管理-能耗组设置
      {
        path: '/ecGroupSetting',
        name: 'ecGroupSetting',
        component: () => import('../views/facilityAndEquipment/runManagement/energyConsumptionManagement/ecGroupSetting/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //能耗管理-能耗分配台账
      {
        path: '/ecAllocationRecord',
        name: 'ecAllocationRecord',
        component: () => import('../views/facilityAndEquipment/runManagement/energyConsumptionManagement/ecAllocationRecord/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //能耗管理-能耗分类组合
      {
        path: '/ecClassificationCombination',
        name: 'ecClassificationCombination',
        component: () => import('../views/facilityAndEquipment/runManagement/energyConsumptionManagement/ecClassificationCombination/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //能耗管理-能耗抄表查询
      {
        path: '/ecMeterQuery',
        name: 'ecMeterQuery',
        component: () => import('../views/facilityAndEquipment/runManagement/energyConsumptionManagement/ecMeterRecord/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //能耗管理-能耗分类查询
      {
        path: '/ecClassificationQuery',
        name: 'ecClassificationQuery',
        component: () => import('../views/facilityAndEquipment/runManagement/energyConsumptionManagement/ecClassificationQuery/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //能耗管理-能耗历史分析
      {
        path: '/ecHistoryAnalysis',
        name: 'ecHistoryAnalysis',
        component: () => import('../views/facilityAndEquipment/runManagement/energyConsumptionManagement/ecHistoryAnalysis/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //查验承接-查验承接报告
      {
        path: '/undertakeInspectionReport',
        name: 'undertakeInspectionReport',
        component: () => import('../views/businessManagement/reportForms/undertakeInspection/undertakeInspectionReport/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      //查验承接-遗留问题整改跟进
      {
        path: '/legacyIssuesRectification',
        name: 'legacyIssuesRectification',
        component: () => import('../views/businessManagement/reportForms/undertakeInspection/legacyIssuesRectification/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/video_environmental_homework',
        name: 'video_environmental_homework',
        component: () => import('V/xtpz/videoTutorial/videoEnvironmentalHomework.vue'),
        meta: {
        }
      },
      {
        path: '/video_project_settings',
        name: 'video_project_settings',
        component: () => import('V/xtpz/videoTutorial/videoProjectSettings.vue'),
        meta: {
        }
      },
      {
        path: '/video_equipment_maintenance',
        name: 'video_equipment_maintenance',
        component: () => import('V/xtpz/videoTutorial/videoEquipmentMaintenance.vue'),
        meta: {
        }
      },
      {
        path: '/video_patrol_handover',
        name: 'video_patrol_handover',
        component: () => import('V/xtpz/videoTutorial/videoPatrolHandover.vue'),
        meta: {
        }
      },
      {
        path: '/video_report_matter',
        name: 'video_report_matter',
        component: () => import('V/xtpz/videoTutorial/videoReportMatter.vue'),
        meta: {
        }
      },
      {
        path: '/video_supervision_management',
        name: 'video_supervision_management',
        component: () => import('V/xtpz/videoTutorial/videoSupervisionManagement.vue'),
        meta: {
        }
      },
      {
        path: '/video_ec_meter',
        name: 'video_ec_meter',
        component: () => import('V/xtpz/videoTutorial/videoEcMeter.vue'),
        meta: {
        }
      },
      {
        path: '/video_report_table',
        name: 'video_report_table',
        component: () => import('V/xtpz/videoTutorial/videoReportTable.vue'),
        meta: {
        }
      },
      {
        path: '/video_ledger',
        name: 'video_ledger',
        component: () => import('V/xtpz/videoTutorial/videoLedger.vue'),
        meta: {
        }
      },
      {
        path: '/video_performance',
        name: 'video_performance',
        component: () => import('V/xtpz/videoTutorial/videoPerformance.vue'),
        meta: {
        }
      },
      {
        path: '/video_abnormal_report',
        name: 'video_abnormal_report',
        component: () => import('V/xtpz/videoTutorial/videoAbnormalReport.vue'),
        meta: {
        }
      },
      {
        path: '/video_work_review_report',
        name: 'video_work_review_report',
        component: () => import('V/xtpz/videoTutorial/videoWorkReviewReport.vue'),
        meta: {
        }
      },
      {
        path: '/lectureVideo',
        name: 'lectureVideo',
        component: () => import('V/xtpz/lectureVideo/lectureVideo.vue'),
        meta: {
        }
      },
      {
        path: '/esVideo',
        name: 'esVideo',
        component: () => import('V/xtpz/esVideo/esVideo.vue'),
        meta: {
        }
      },
      {
        path: '/lectureVideoWeChat',
        name: 'lectureVideoWeChat',
        component: () => import('V/xtpz/lectureVideo/lectureVideoWeChat.vue'),
        meta: {
        }
      },
      {
        path: '/video_supervision_system_online',
        name: 'video_supervision_system_online',
        component: () => import('V/xtpz/videoTutorial/videoSupervisionSystemOnline.vue'),
        meta: {
        }
      },


    ]
  },
]

import Router from 'vue-router';
Vue.use(Router)
//路由导航冗余报错（路由重复）
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
  if (store.state.token || to.path == '/login'|| to.path == '/WeChatLogin') {
    gotoPage(to, from, next)
  } else {
    if (getCache('token')) {
      let cacheDatas = getAllCache();
      store.commit('setCacheDatas', cacheDatas);
      // gotoPage(to, from, next);
      // 刷新页面回到首页
      next('/')
    } else {
      if(to.path == '/login'){
        next("/login");
      }else if(to.path == '/WeChatLogin'){
        next("/WeChatLogin");
      }
    }
  }
})

const gotoPage = (to, from, next) => {
  // 回到页面顶部
  try {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  } catch (e) {
    document.body.scrollTop = 0;
  }
  // 路由参数缓存
  let params = to.params;
  if (params) {
    if (Object.keys(params).length) {
      store.commit('setPageParams', {
        path: to.path,
        params: params
      });
    } else if (store.state.paramsMap[to.path]) {
      params = store.state.paramsMap[to.path];
      for (let key in params) {
        to.params[key] = params[key];
      }
    }
  }
  next();
}

router.afterEach((to, from) => {
  // 当前菜单选中状态
  store.commit('main/setSelectedKeys', [to.name])
  // 顶部tab页
  if (to.path) {
    store.dispatch('main/addTab', to.name)
  }
  store.commit('main/setSelectedTab', to.name)
})

export default router
